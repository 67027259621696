export default class Place {
  public id: number
  public name: string

  public constructor(dict: Record<string, unknown>) {
    if (!dict) {
      dict = {}
    }
    this.id = (dict.id as number) || null
    this.name = (dict.name as string) || ''
  }
}
