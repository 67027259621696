export default class Slot {
  public calendarId: number
  public scheduleId: number
  public serviceId: number
  public resourceId: number
  public placeId: number

  public constructor(dict: Record<string, unknown>) {
    if (!dict) {
      dict = {}
    }
    this.calendarId = (dict.calendarId as number) || null
    this.scheduleId = (dict.scheduleId as number) || null
    this.serviceId = (dict.serviceId as number) || null
    this.resourceId = (dict.resourceId as number) || null
    this.placeId = (dict.placeId as number) || null
  }
}
