class PrimaryServiceConnection {
  public primaryServiceId: number
  public addonServiceId: number
  public isRequired: boolean
}

export default class Service {
  public id: number
  public description: string
  public duration: number
  public name: string
  public type: string
  public mode: string
  public salesPriceIncVat: string
  public sortOrder: number
  public visibility: string

  private readonly primaryServices: Array<PrimaryServiceConnection>

  // virtual
  public isRequired: boolean
  public isSelected: boolean

  public constructor(dict: Record<string, unknown>) {
    if (!dict) {
      dict = {}
    }
    this.id = (dict.id as number) || null
    this.description = (dict.description as string) || ''
    this.duration = (dict.duration as number) || 0
    this.name = (dict.name as string) || ''
    this.type = (dict.type as string) || ''
    this.mode = (dict.mode as string) || ''
    this.primaryServices = (dict.primaryServices as Array<PrimaryServiceConnection>) || []
    this.isRequired = false
    this.isSelected = false
    this.sortOrder = (dict.sortOrder as number) || 0
    this.salesPriceIncVat = (dict.salesPriceIncVat as string) || ''
    this.visibility = (dict.visibility as string) || ''
  }

  public isVisibleForPrimaryService(primaryServiceId: number): boolean {
    if (!primaryServiceId) {
      return false
    }
    if (this.type === 'AddonForAll') {
      return true
    }
    return !!this.getPrimaryServiceConnection(primaryServiceId)
  }

  public isRequiredForPrimaryService(primaryServiceId: number): boolean {
    if (!primaryServiceId) {
      return false
    }
    if (this.type === 'AddonForAll') {
      return false
    }
    const ps = this.getPrimaryServiceConnection(primaryServiceId)
    return ps?.isRequired
  }

  private getPrimaryServiceConnection(primaryServiceId: number): PrimaryServiceConnection {
    for (let j = 0; j < this.primaryServices.length; j++) {
      if (this.primaryServices[j].primaryServiceId === primaryServiceId) {
        return this.primaryServices[j]
      }
    }
    return null
  }
}
