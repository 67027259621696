import { DateTime } from 'luxon'
import { vxm } from '@/store/index'

// todo: actually use timeformat from store

export function formatDateTime(dt: DateTime, timezone: string, timeformat: string): string {
  if (!dt) {
    return ''
  }
  return dt.setZone(timezone).setLocale(timeformat).toLocaleString(DateTime.DATETIME_FULL)
}

export function formatDate(dt: DateTime, timezone: string, timeformat: string): string {
  if (!dt) {
    return ''
  }
  return dt.setZone(timezone).setLocale(timeformat).toLocaleString(DateTime.DATE_FULL)
}

export function formatTime(dt: DateTime, timezone: string, timeformat: string): string {
  if (!dt) {
    return ''
  }
  return dt.setZone(timezone).setLocale(timeformat).toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function localTimeStringToUtcString(t: string, timezone: string): string {
  if (!t) {
    return ''
  }
  const dt = DateTime.fromISO(t.replace(' ', 'T'), { zone: timezone })
  return dt.setZone('UTC').toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
}
