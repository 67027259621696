export default class Portal {
  public id: number = null
  public name = ''
  public calendarId: number = null
  public requireConfirmation = false
  public requireFieldName = false
  public requireFieldEmail = false
  public showServicePrices = false
  public hidePlaceOnReBooking = false

  public populate(data: Record<string, unknown>): void {
    if (!data) {
      data = {}
    }
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        this[key.toString()] = data[key]
      }
    }
  }

  public get requiredCustomerFields(): Array<string> {
    const required = []
    if (this.requireFieldName) {
      required.push('name')
    }
    if (this.requireFieldEmail) {
      required.push('email')
    }
    return required
  }
}
