export default class Resource {
  public id: number
  public name: string
  public isAutomatic: boolean
  
  public static anyResourceId = -1

  public constructor(dict: Record<string, unknown>) {
    if (!dict) {
      dict = {}
    }
    this.id = (dict.id as number) || null
    this.name = (dict.name as string) || ''
    this.isAutomatic = (dict.isAutomatic as boolean) || false
  }
  
  public static newAnyResource(): Resource {
    return new Resource({ id: Resource.anyResourceId, name: 'Automatic', isAutomatic: true })
  }

}
