export default class Locale {
  public timezone: string
  public language: string
  public currency: string
  public timeFormat: string
  public countryCode: string
  public countryName: string

  public constructor(dict: Record<string, unknown>) {
    if (!dict) {
      dict = {}
    }
    this.timezone = (dict.timezone as string) || ''
    this.language = (dict.language as string) || ''
    this.currency = (dict.currency as string) || ''
    this.timeFormat = (dict.timeFormat as string) || ''
    this.countryCode = (dict.countryCode as string) || ''
    this.countryName = (dict.countryName as string) || ''
  }
}
